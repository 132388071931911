.clickable {
  position: relative;
  cursor: pointer;

  &:active {
    top: 1px;
    left: 1px;
  }
}


// Margin top

.mt-xs-200 {
  margin-top: 200px;
}

.mt-xs-160 {
  margin-top: 160px;
}

.mt-xs-120 {  
  margin-top: 120px
}

.mt-xs-80 {
  margin-top: 80px;
}

.mt-xs-40 {
  margin-top: 40px;
}

.mt-xs-20 {
  margin-top: 20px;
}

.mt-xs-0 {
  margin-top: 0px;
}

@media (min-width: $breakpoint-small) {
  .mt-sm-200 {
    margin-top: 200px;
  }

  .mt-sm-160 {
    margin-top: 160px;
  }

  .mt-sm-120 {  
    margin-top: 120px
  }

  .mt-sm-80 {
    margin-top: 80px;
  }

  .mt-sm-40 {
    margin-top: 40px;
  }

  .mt-sm-20 {
    margin-top: 20px;
  }

  .mt-sm-0 {
    margin-top: 0px;
  }
}

@media (min-width: $breakpoint-medium) {
  .mt-md-200 {
    margin-top: 200px;
  }

  .mt-md-160 {
    margin-top: 160px;
  }

  .mt-md-120 {  
    margin-top: 120px
  }

  .mt-md-80 {
    margin-top: 80px;
  }

  .mt-md-40 {
    margin-top: 40px;
  }

  .mt-md-20 {
    margin-top: 20px;
  }

  .mt-md-0 {
    margin-top: 0px;
  }
}

@media (min-width: $breakpoint-large) {
  .mt-lg-200 {
    margin-top: 200px;
  }

  .mt-lg-160 {
    margin-top: 160px;
  }

  .mt-lg-120 {  
    margin-top: 120px
  }

  .mt-lg-80 {
    margin-top: 80px;
  }

  .mt-lg-40 {
    margin-top: 40px;
  }

  .mt-lg-20 {
    margin-top: 20px;
  }

  .mt-lg-0 {
    margin-top: 0px;
  }
}


// Negative margin top

.nmt-xs-300 {
  margin-top: -300px;
}

.nmt-xs-200 {
  margin-top: -200px;
}

.nmt-xs-160 {
  margin-top: -160px;
}

.nmt-xs-120 {  
  margin-top: -120px
}

.nmt-xs-80 {
  margin-top: -80px;
}

.nmt-xs-40 {
  margin-top: -40px;
}

.nmt-xs-20 {
  margin-top: -20px;
}

.nmt-xs-0 {
  margin-top: -0px;
}

@media (min-width: $breakpoint-small) {
  .nmt-sm-300 {
    margin-top: -300px;
  }

  .nmt-sm-200 {
    margin-top: -200px;
  }

  .nmt-sm-160 {
    margin-top: -160px;
  }

  .nmt-sm-120 {  
    margin-top: -120px
  }

  .nmt-sm-80 {
    margin-top: -80px;
  }

  .nmt-sm-40 {
    margin-top: -40px;
  }

  .nmt-sm-20 {
    margin-top: -20px;
  }

  .nmt-sm-0 {
    margin-top: -0px;
  }
}

@media (min-width: $breakpoint-medium) {
  .nmt-md-300 {
    margin-top: -300px;
  }

  .nmt-md-200 {
    margin-top: -200px;
  }

  .nmt-md-160 {
    margin-top: -160px;
  }

  .nmt-md-120 {  
    margin-top: -120px
  }

  .nmt-md-80 {
    margin-top: -80px;
  }

  .nmt-md-40 {
    margin-top: -40px;
  }

  .nmt-md-20 {
    margin-top: -20px;
  }

  .nmt-md-0 {
    margin-top: -0px;
  }
}

@media (min-width: $breakpoint-large) {
  .nmt-lg-300 {
    margin-top: -300px;
  }

  .nmt-lg-200 {
    margin-top: -200px;
  }

  .nmt-lg-160 {
    margin-top: -160px;
  }

  .nmt-lg-120 {  
    margin-top: -120px
  }

  .nmt-lg-80 {
    margin-top: -80px;
  }

  .nmt-lg-40 {
    margin-top: -40px;
  }

  .nmt-lg-20 {
    margin-top: -20px;
  }

  .nmt-lg-0 {
    margin-top: -0px;
  }
}


// Margin bottom

.mb-xs-300 {
  margin-bottom: 300px;
}

.mb-xs-200 {
  margin-bottom: 200px;
}

.mb-xs-160 {
  margin-bottom: 160px;
}

.mb-xs-120 {  
  margin-bottom: 120px
}

.mb-xs-80 {
  margin-bottom: 80px;
}

.mb-xs-40 {
  margin-bottom: 40px;
}

.mb-xs-20 {
  margin-bottom: 20px;
}

.mb-xs-0 {
  margin-bottom: 0px;
}

@media (min-width: $breakpoint-small) {
  .mb-sm-300 {
    margin-bottom: 300px;
  }

  .mb-sm-200 {
    margin-bottom: 200px;
  }

  .mb-sm-160 {
    margin-bottom: 160px;
  }

  .mb-sm-120 {  
    margin-bottom: 120px
  }

  .mb-sm-80 {
    margin-bottom: 80px;
  }

  .mb-sm-40 {
    margin-bottom: 40px;
  }

  .mb-sm-20 {
    margin-bottom: 20px;
  }

  .mb-sm-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: $breakpoint-medium) {
  .mb-md-300 {
    margin-bottom: 300px;
  }

  .mb-md-200 {
    margin-bottom: 200px;
  }

  .mb-md-160 {
    margin-bottom: 160px;
  }

  .mb-md-120 {  
    margin-bottom: 120px
  }

  .mb-md-80 {
    margin-bottom: 80px;
  }

  .mb-md-40 {
    margin-bottom: 40px;
  }

  .mb-md-20 {
    margin-bottom: 20px;
  }

  .mb-md-0 {
    margin-bottom: 0px;
  }
}

@media (min-width: $breakpoint-large) {
  .mb-lg-160 {
    margin-bottom: 160px;
  }

  .mb-lg-120 {  
    margin-bottom: 120px
  }

  .mb-lg-80 {
    margin-bottom: 80px;
  }

  .mb-lg-40 {
    margin-bottom: 40px;
  }

  .mb-lg-20 {
    margin-bottom: 20px;
  }

  .mb-lg-0 {
    margin-bottom: 0px;
  }
}


// Negative margin bottom

.nmb-xs-300 {
  margin-bottom: -300px;
}

.nmb-xs-200 {
  margin-bottom: -200px;
}

.nmb-xs-160 {
  margin-bottom: -160px;
}

.nmb-xs-120 {  
  margin-bottom: -120px
}

.nmb-xs-80 {
  margin-bottom: -80px;
}

.nmb-xs-40 {
  margin-bottom: -40px;
}

.nmb-xs-20 {
  margin-bottom: -20px;
}

.nmb-xs-0 {
  margin-bottom: -0px;
}

@media (min-width: $breakpoint-small) {
  .nmb-sm-300 {
    margin-bottom: -300px;
  }

  .nmb-sm-200 {
    margin-bottom: -200px;
  }

  .nmb-sm-160 {
    margin-bottom: -160px;
  }

  .nmb-sm-120 {  
    margin-bottom: -120px
  }

  .nmb-sm-80 {
    margin-bottom: -80px;
  }

  .nmb-sm-40 {
    margin-bottom: -40px;
  }

  .nmb-sm-20 {
    margin-bottom: -20px;
  }

  .nmb-sm-0 {
    margin-bottom: -0px;
  }
}

@media (min-width: $breakpoint-medium) {
  .nmb-md-300 {
    margin-bottom: -300px;
  }

  .nmb-md-200 {
    margin-bottom: -200px;
  }

  .nmb-md-160 {
    margin-bottom: -160px;
  }

  .nmb-md-120 {  
    margin-bottom: -120px
  }

  .nmb-md-80 {
    margin-bottom: -80px;
  }

  .nmb-md-40 {
    margin-bottom: -40px;
  }

  .nmb-md-20 {
    margin-bottom: -20px;
  }

  .nmb-md-0 {
    margin-bottom: -0px;
  }
}

@media (min-width: $breakpoint-large) {
  .nmb-lg-300 {
    margin-bottom: -300px;
  }

  .nmb-lg-200 {
    margin-bottom: -200px;
  }

  .nmb-lg-160 {
    margin-bottom: -160px;
  }

  .nmb-lg-120 {  
    margin-bottom: -120px
  }

  .nmb-lg-80 {
    margin-bottom: -80px;
  }

  .nmb-lg-40 {
    margin-bottom: -40px;
  }

  .nmb-lg-20 {
    margin-bottom: -20px;
  }

  .nmb-lg-0 {
    margin-bottom: -0px;
  }
}


// Negative margin left

.nml-xs-300 {
  margin-left: -300px;
}

.nml-xs-200 {
  margin-left: -200px;
}

.nml-xs-160 {
  margin-left: -160px;
}

.nml-xs-120 {  
  margin-left: -120px
}

.nml-xs-80 {
  margin-left: -80px;
}

.nml-xs-40 {
  margin-left: -40px;
}

.nml-xs-20 {
  margin-left: -20px;
}

.nml-xs-0 {
  margin-left: -0px;
}

@media (min-width: $breakpoint-small) {
  .nml-sm-300 {
    margin-left: -300px;
  }

  .nml-sm-200 {
    margin-left: -200px;
  }

  .nml-sm-160 {
    margin-left: -160px;
  }

  .nml-sm-120 {  
    margin-left: -120px
  }

  .nml-sm-80 {
    margin-left: -80px;
  }

  .nml-sm-40 {
    margin-left: -40px;
  }

  .nml-sm-20 {
    margin-left: -20px;
  }

  .nml-sm-0 {
    margin-left: -0px;
  }
}

@media (min-width: $breakpoint-medium) {
  .nml-md-300 {
    margin-left: -300px;
  }

  .nml-md-200 {
    margin-left: -200px;
  }

  .nml-md-160 {
    margin-left: -160px;
  }

  .nml-md-120 {  
    margin-left: -120px
  }

  .nml-md-80 {
    margin-left: -80px;
  }

  .nml-md-40 {
    margin-left: -40px;
  }

  .nml-md-20 {
    margin-left: -20px;
  }

  .nml-md-0 {
    margin-left: -0px;
  }
}

@media (min-width: $breakpoint-large) {
  .nml-lg-300 {
    margin-left: -300px;
  }

  .nml-lg-200 {
    margin-left: -200px;
  }

  .nml-lg-160 {
    margin-left: -160px;
  }

  .nml-lg-120 {  
    margin-left: -120px
  }

  .nml-lg-80 {
    margin-left: -80px;
  }

  .nml-lg-40 {
    margin-left: -40px;
  }

  .nml-lg-20 {
    margin-left: -20px;
  }

  .nml-lg-0 {
    margin-left: -0px;
  }
}

// Negative margin right

.nmr-xs-200 {
  margin-right: -200px;
}

.nmr-xs-160 {
  margin-right: -160px;
}

.nmr-xs-120 {  
  margin-right: -120px
}

.nmr-xs-80 {
  margin-right: -80px;
}

.nmr-xs-40 {
  margin-right: -40px;
}

.nmr-xs-20 {
  margin-right: -20px;
}

.nmr-xs-0 {
  margin-right: -0px;
}

@media (min-width: $breakpoint-small) {
  .nmr-sm-200 {
    margin-right: -200px;
  }

  .nmr-sm-160 {
    margin-right: -160px;
  }

  .nmr-sm-120 {  
    margin-right: -120px
  }

  .nmr-sm-80 {
    margin-right: -80px;
  }

  .nmr-sm-40 {
    margin-right: -40px;
  }

  .nmr-sm-20 {
    margin-right: -20px;
  }

  .nmr-sm-0 {
    margin-right: -0px;
  }
}

@media (min-width: $breakpoint-medium) {
  .nmr-md-200 {
    margin-right: -200px;
  }

  .nmr-md-160 {
    margin-right: -160px;
  }

  .nmr-md-120 {  
    margin-right: -120px
  }

  .nmr-md-80 {
    margin-right: -80px;
  }

  .nmr-md-40 {
    margin-right: -40px;
  }

  .nmr-md-20 {
    margin-right: -20px;
  }

  .nmr-md-0 {
    margin-right: -0px;
  }
}

@media (min-width: $breakpoint-large) {
  .nmr-lg-200 {
    margin-right: -200px;
  }

  .nmr-lg-160 {
    margin-right: -160px;
  }

  .nmr-lg-120 {  
    margin-right: -120px
  }

  .nmr-lg-80 {
    margin-right: -80px;
  }

  .nmr-lg-40 {
    margin-right: -40px;
  }

  .nmr-lg-20 {
    margin-right: -20px;
  }

  .nmr-lg-0 {
    margin-right: -0px;
  }
}

// Heights

.h-xs-200 {
  height: 200px;
}

.h-xs-300 {
  height: 300px;
}

.h-xs-400 {
  height: 400px;
}

.h-xs-500 {
  height: 500px;
}

.h-xs-600 {
  height: 600px;
}

.h-xs-800 {
  height: 800px;
}

.h-xs-1000 {
  height: 1000px;
}

.h-xs-1200 {
  height: 1200px;
}


@media (min-width: $breakpoint-small) {
  .h-sm-200 {
    height: 200px;
  }

  .h-sm-300 {
    height: 300px;
  }

  .h-sm-400 {
    height: 400px;
  }

  .h-sm-500 {
    height: 500px;
  }

  .h-sm-600 {
    height: 600px;
  }

  .h-sm-800 {
    height: 800px;
  }
  
  // This is a custom sizing used on the frontpage
  .h-sm-895 {
    height: 895px;
  }

  .h-sm-1000 {
    height: 1000px;
  }

  .h-sm-1200 {
    height: 1200px;
  }
}


@media (min-width: $breakpoint-medium) {
  .h-md-200 {
    height: 200px;
  }

  .h-md-300 {
    height: 300px;
  }

  .h-md-400 {
    height: 400px;
  }

  .h-md-500 {
    height: 500px;
  }

  .h-md-600 {
    height: 600px;
  }

  .h-md-800 {
    height: 800px;
  }

  .h-md-1000 {
    height: 1000px;
  }

  .h-md-1200 {
    height: 1200px;
  }
}


@media (min-width: $breakpoint-large) {
  .h-lg-200 {
    height: 200px;
  }

  .h-lg-300 {
    height: 300px;
  }

  .h-lg-400 {
    height: 400px;
  }

  .h-lg-500 {
    height: 500px;
  }

  .h-lg-600 {
    height: 600px;
  }

  .h-lg-800 {
    height: 800px;
  }

  .h-lg-1000 {
    height: 1000px;
  }

  .h-lg-1200 {
    height: 1200px;
  }
}

// Hide/Show

.hide-xs {
  display: none;
}

.show-xs {
  display: block;
}


@media (min-width: $breakpoint-small) {
  .hide-sm {
    display: none;
  }

  .show-sm {
    display: block;
  }
}


@media (min-width: $breakpoint-medium) {
  .hide-md {
    display: none;
  }

  .show-md {
    display: block;
  }
}


@media (min-width: $breakpoint-large) {
  .hide-lg {
    display: none;
  }

  .show-lg {
    display: block;
  }
}

// Alighn

.talign-xs-right {
  text-align: right;
}


@media (min-width: $breakpoint-small) {
  .talign-sm-right {
    text-align: right;
  }
}


@media (min-width: $breakpoint-medium) {
  .talign-md-right {
    text-align: right;
  }
}


@media (min-width: $breakpoint-large) {
  .talign-lg-right {
    text-align: right;
  }
}


// Colors

.color-xs-primary {
  color: $color-primary;
}


@media (min-width: $breakpoint-small) {
  .color-sm-primary {
    color: $color-primary;
  }
}


@media (min-width: $breakpoint-medium) {
  .color-md-primary {
    color: $color-primary;
  }
}


@media (min-width: $breakpoint-large) {
  .color-lg-primary {
    color: $color-primary;
  }
}


.color-xs-light-gray {
  color: $color-light-gray;
}


@media (min-width: $breakpoint-small) {
  .color-sm-light-gray {
    color: $color-light-gray;
  }
}


@media (min-width: $breakpoint-medium) {
  .color-md-light-gray {
    color: $color-light-gray;
  }
}


@media (min-width: $breakpoint-large) {
  .color-lg-light-gray {
    color: $color-light-gray;
  }
}