.panorama iframe {
    width: 100%;
    min-height: 60rem;
    border: 0;
    background: white;
}

@media (max-width: $breakpoint-small) {
    .panorama iframe {
        min-height: 40rem;
    }
}