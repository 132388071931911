.accordian {
}

  .accordian--bordered {
    border-bottom: 1px solid $color-primary;

    .accordian__page {
      border-top: 1px solid $color-primary;
    }
  }

  .accordian--bordered.accordian--dark {
    border-bottom: 1px solid $color-dark-gray;

    .accordian__page {
      border-top: 1px solid $color-dark-gray;
    }
  }

  .accordian--dark {
    .accordian__page {
      border-top: 1px solid $color-dark-gray;
    }

    .accordian__svg {
      fill: $color-dark-gray;
    }

    .accordian__title {
      color: $color-dark-gray;
    }
  }

.accordian__page {
  display: flex;
  flex-direction: column;
}

  .accordian__page--active {

    .accordian__content {
    }

    .accordian__svg {
      transform: rotate(135deg);
    }
  }

.accordian__header {
  cursor: pointer;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordian__svg {
  transition: transform 300ms ease-in-out;
  height: 1rem;
  fill: $color-primary;
  transform: rotate(45deg);
}

.accordian__title {
  font-size: 2.2rem;
  line-height: 2.2rem;
  color: $color-primary;
  margin: 0;
}

.accordian__content {
  display: none;
  padding: 2rem 0;
}