* {
  box-sizing: border-box !important;
}

html, body {
  height: 100%;
  width: 100%;
}

html {
  font-size: 62.5%; // 16px * 62.5%: Base 10 font sizing (i.e. 1.0rem == 10px )
}

@media (max-width: 800px) {
  html {
    font-size: 56.25%; // 1rem = 9px
  } 
}

body {
  @include font-neue;
  color: $color-dark-gray;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.4rem;
  margin: 0;
}

.page {
  min-height: 100%;
}

.main {
  overflow-x: hidden;
}


// Headers

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-dark-gray;
  font-weight: 300;
  margin-top: 0;

  a {
    color: $color-dark-gray;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

h1 {
  font-size: 3.0rem;
  line-height: 4.5rem;
  margin-bottom: 3.0rem;
}

h2 {
  font-size: 2.8rem;
  line-height: 4.2rem;
  margin-bottom: 2.8rem;
}

h3 {
  font-size: 2.6rem;
  line-height: 3.9rem;
  margin-bottom: 2.6rem;
}

h4 {
  font-size: 2.4rem;
  line-height: 3.6rem;
  margin-bottom: 2.4rem;
}

h5 {
  font-size: 2.2rem;
  line-height: 3.33rem;
  margin-bottom: 2.2rem;
}


// Text

p {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: 0;

  a {
    text-decoration: underline;
  }
}

small, .small {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.6rem;
  font-weight: 300;
}

.tiny {
  font-size: 1.3rem;
  line-height: 1.69rem;
  font-weight: 300;
}


// Links

a {
  transition: all 100ms linear;
  color: $color-primary;
  cursor: pointer;
  text-decoration: none;  
  margin: 0;
}

  a:hover {
    
  }

  a:link,
  .link:link {

  }

  a:visited,
  .link:visited {

  }

  a:hover,
  .link:hover {
    text-decoration: underline;
  }

  a:active,
  .link:active {

  }

  a:focus,
  .link:focus {
    outline: none;
  }

  .link--quiet {
    color: $color-dark-gray;
  }

  .link--no-underline {
    text-decoration: none;
  }


hr, .hr {
  margin: 0;
  border: 0;
  height: 1px;
  background: $color-dark-gray;
}

.hr--primary {
  background: $color-primary;
}


img {
  display: block;
  margin: 0;
}

// quotes
blockquote {
  p {
  }

  p code {
  }
}

// inline code
p code,
li code {
}


address {
  font-style: normal;
}

// block code
pre {
}

  pre code {

  }

figure {
  margin: 0;
}

pre {
}


cite {

}

big {

}

tt {

}

samp {

}

del {

}

ins {

}

q {

}

// Selections

::selection {
  background: $color-primary;
  color: white;
}

::-moz-selection {
  background: $color-primary;
  color: white;
}

// Buttons

button, .button {

}

.button {
  display: inline-block;
  font-size: 2.2rem;
  line-height: 2.2rem;
  transition: 300ms all linear;
  transform: translateY(0);
  text-decoration: none;
  font-weight: 300;

  &:hover {
    text-decoration: none;
    
    .icon {
      transform: translateX(2px);
    }
  }

  .icon {
    display: inline-block;
    transition: all 250ms linear;
    margin: 0 0 0 .25rem;
    height: 1.6rem;
    padding: .3rem 0;

    path {
      fill: $color-primary;
    }
  }
}

.button--reverse {
  .icon {
    margin: 0 .25rem 0 0;
  }

  &:hover {
    .icon {
      transform: translateX(-2px);
    }
  }
}

.button--small {
  font-size: 1.6rem;
  line-height: 1.6rem;

  .icon {
    height: 1.4rem;
    padding: .2rem 0 0 0;
  }
}

.button--reset {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
}