.section-footer {
  display: flex;
  justify-content: center;
}

.section-footer__icon {
  transition: all 300ms ease-in;
  transform: translateY(0);

  &:hover {
    transform: translateY(0.5rem);
  }

  svg {
    cursor: pointer;
    height: 2rem;
    fill: $color-primary;
  }
}