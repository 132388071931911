.svg {
  display: block;
}

a {
  .svg {
    fill: $color-primary;
  }
}

// Arrow

.svg--arrow { 
}

.svg--arrow-right {
  transform: rotate(-90deg);
}

.svg--arrow-left {
  transform: rotate(90deg);
}

// Cross

.svg--cross {

}

// Close

.svg--close {
  fill: $color-dark-gray;
}

// Hamburger

.svg--hamburger {
  transform: scaleX(-1);
  
  rect { 
    transition: width 400ms ease-in-out;
  }
}
