.filters {

}

.filters__list {
  margin: 0;
  padding: 0;
}

.filters__item {
  position: relative;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0 0 1.25rem 0;
  padding: 0 0 0 1.25rem;
  list-style: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);

  &.open {
    .filters__sublist {
      //display: block;
    }

    .filters__svg {
      transform: rotate(90deg);
    }
  }

  &.active {
    .filters__link {
      color: $color-medium-gray;
    }

    .filters__link--primary {
      color: $color-primary;
      font-weight: 400;
    }

    .filters__svg {
      fill: $color-medium-gray;
    }
  }

  &--short {
    margin-bottom: .25rem;
  }
}

.filters__link {
  display: block;
  color: $color-dark-gray;

  &:hover {
    text-decoration: none;
  }
}


.filters__svg {
  height: 7px;
  position: absolute;
  left: 0px;
  top: 9px;
  transition: transform 200ms ease-in-out;
  fill: $color-dark-gray;
  transform: rotate(45deg);
}

// Submenu

.filters__sublist {
  display: none;
  margin: 1.5rem 0;
  padding: 0;
}

.filters__sublist__item {
  margin: 0 0 .5rem 0;
  padding: 0 0 0 1.5rem;
  font-size: 1.6rem;
  line-height: 2.24rem;
  list-style: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

.filters__sublist__link {
  color: $color-medium-gray;
  text-decoration: none;

  &.active {
    text-decoration: underline;
  }
}