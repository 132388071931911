$image-overlay-background: rgba(67, 66, 68, .80);
$image-overlay-color: rgba(255, 255, 255, 0.95);

.image {
}

  .image--fill {
    height: 100%;
    width: 100%;

    .image__content {
      height: 100%;
      width: 100%;
    }
  }

  .image--fade {

    .image__content,
    .image__banner {
      transition: opacity 250ms linear;
      opacity: .75;
    }

    &:hover {

      .image__content,
      .image__banner {
        opacity: 1;
      }
    }
  }

  .image--b-and-w {
    .image__img,
    .image__bgimg {
      filter: grayscale(100%);
    }

    &:hover {
      .image__img,
      .image__bgimg {
        filter: grayscale(0);
      }
    }
  }

.image__content {
  //background-color: $color-dark-gray;
  position: relative;
  display: block;
  z-index: 0;
}

.image__content--white {
  background-color: white;
}

.image__img,
.image__bgimg {
  display: block;
  margin: 0;
  width: 100%;
  border: 0;
  transition: all 500ms linear 250ms;
  opacity: 0;

  &.lazyload {
    
  }

  &.lazyloaded {
    opacity: 1;
  }
}

.image__bgimg {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

// Loader

// .image__loader {
//   z-index: -1;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   margin-left: -2.5rem;
//   margin-top: -2.5rem;
//   background: none;
//   height: 5rem;
//   width: 5rem;
//   border: 0;
//   outline: 0;
// }

// .image__loader__background,
// .image__loader__foreground {
//   position: absolute;
//   width: 5rem;
//   height: 5rem;
//   border-radius: 100%;
//   background: $color-primary;
// }

// .image__loader__background {
//   animation: pulse 2s ease infinite;
// }

// .image__loader__foreground {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: none;
//   cursor: pointer;
//   outline: none;
// }

// Overlay

.image__overlay {
  background-color: $image-overlay-background;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 2rem;
  opacity: 0;
  transition: opacity 200ms linear;
}

  .image:hover {
    .image__overlay {
      opacity: 1;
    }

    .image__overlay__text {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .image__overlay__text {
    transition: opacity 200ms linear, transform 200ms ease-out;
    opacity: 0;
    transform: translateY(2rem);
    font-size: 2rem;
    line-height: 3rem;
    color: $image-overlay-color;
  }

  .image__overlay__text--small {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

// Caption

.image__caption {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  color: $image-overlay-color;
  width: 100%;
  padding: 2rem;
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 400;
}

// Banner

.image__banner {
  transition: padding 100ms linear;
  background-color: $image-overlay-background;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;
  width: 100%;
}

  .image:hover .image__banner {
    padding-bottom: 2.5rem;
  }

.image__banner__title,
.image__banner__subtitle {
  color: $image-overlay-color;
}

.image__banner__title {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.52rem;
  font-weight: 400;
}

.image__banner__subtitle {
  margin: 0;
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 200;
}

// Outer caption

.image__outer-caption {
  margin-top: 1rem;
}

.image__outer-caption__title {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.52rem;
  font-weight: 500;
}

.image__outer-caption__subtitle {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.96rem;
  font-weight: 300;
}

.image__outer-caption__subtitle--light {
  color: $color-medium-gray;
}

@media (min-width: $breakpoint-small) {
  .image__outer-caption {
  } 
}

// Fullscreen

.image__fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  transition: all 200ms linear;
  visibility: hidden;
  opacity: 0;
}

  .image--show-fullscreen {
    .image__fullscreen {
      visibility: visible;
      opacity: 1;
    }
  }

.image__fullscreen__close {
  position: absolute;
  top: 3rem;
  right: 3rem;

  svg {
    height: 4rem;
  }
}

.image__fullscreen__image {
  max-height: 100%;
  max-width: 100%;
}