// Neue Haas
//

// Light
@font-face {
  font-family: 'neue-haas-unica';
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Light.eot');
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Light.woff2') format('woff2'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Light.woff') format('woff'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Light.ttf') format('truetype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Light.svg#neue-haas-unica-pro-Light') format('svg');
  font-weight: 200;
  font-style: regular;
}

@font-face {
  font-family: 'neue-haas-unica';
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-LightIt.eot');
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-LightIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-LightIt.woff2') format('woff2'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-LightIt.woff') format('woff'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-LightIt.ttf') format('truetype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-LightIt.svg#neue-haas-unica-pro-LightIt') format('svg');
  font-weight: 200;
  font-style: italic;
}

// Regular
@font-face {
  font-family: 'neue-haas-unica';
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Regular.eot');
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Regular.woff2') format('woff2'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Regular.woff') format('woff'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Regular.ttf') format('truetype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Regular.svg#neue-haas-unica-pro-Regular') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'neue-haas-unica';
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Italic.eot');
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Italic.woff2') format('woff2'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Italic.woff') format('woff'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Italic.ttf') format('truetype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Italic.svg#neue-haas-unica-pro-Italic') format('svg');
  font-weight: 300;
  font-style: italic;
}

// Medium
@font-face {
  font-family: 'neue-haas-unica';
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Medium.eot');
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Medium.woff2') format('woff2'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Medium.woff') format('woff'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Medium.ttf') format('truetype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Medium.svg#neue-haas-unica-pro-Medium') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'neue-haas-unica';
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-MediumIt.eot');
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-MediumIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-MediumIt.woff2') format('woff2'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-MediumIt.woff') format('woff'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-MediumIt.ttf') format('truetype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-MediumIt.svg#neue-haas-unica-pro-MediumIt') format('svg');
  font-weight: 400;
  font-style: italic;
}

// Bold
@font-face {
  font-family: 'neue-haas-unica';
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Bold.eot');
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Bold.woff2') format('woff2'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Bold.woff') format('woff'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Bold.ttf') format('truetype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-Bold.svg#neue-haas-unica-pro-Bold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'neue-haas-unica';
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-BoldItalic.eot');
  src: url('../fonts/neue-haas-unica/neue-haas-unica-pro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-BoldItalic.woff2') format('woff2'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-BoldItalic.woff') format('woff'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-BoldItalic.ttf') format('truetype'),
    url('../fonts/neue-haas-unica/neue-haas-unica-pro-BoldItalic.svg#neue-haas-unica-pro-BoldItalic') format('svg');
  font-weight: 400;
  font-style: italic;
}
