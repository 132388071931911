.footer {
  color: white;
}

// Logos
.footer-logo {

}

.footer-logo__link {
  display: block;
}

.footer-logo__image {
  display: block;
  max-width: 10rem;
  max-height: 8rem;
}

.footer-social-list {
  margin: 0;
  padding: 0;
  display: flex;
}

.footer-social-list__item {
  margin: 0 10px 0 0;
  padding: 0;
  list-style: none;

  img {
    height: 40px;
  }
}