.sticky-sidebar {
  position: absolute;
  //z-index: 10;
}

// Only perform stickiness on non-mobile
@media (min-width: $breakpoint-small) {
  .sticky-sidebar--sticky {
    position: fixed;
  }

  .sticky-sidebar--bottom {
    top: auto;
    bottom: 0;
  }
}

@media (max-width: $breakpoint-small - 1) {
  .sticky-sidebar {
    position: static;
  }
}