/**
 * Styles for long-form user content such as blog posts, flatpages, and project
 * content.
 */
.post-content {

  h1, h2, h3, h4, h5, h6 {
  }

  h1 {

  }

  h2 {
    font-size: 2.6rem;
    line-height: 3.9rem;
    margin-bottom: 2.6rem;
    margin-top: 3.9rem;
  }

  h3 {
    font-size: 2.3rem;
    line-height: 3.45rem;
    margin-bottom: 2.3rem;
    margin-top: 3.45rem;
  }

  h4 {
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }

  h5 {
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 1.8rem;
    margin-top: 2.8rem;
    font-weight: 400;
  }

  h6 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;
    font-weight: 400;    
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;
  }

  a {
    display: inline;
    color: $color-primary !important;
  }

  img {
    display: block;
    max-width: 100%;
  }

  ul, ol {

  }

  li {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  table {
    width: 100%;
    margin: 3rem 0 4rem 0;
    table-layout: fixed;

    th, td {
      border: 1px solid $color-dark-gray;
    }

    th {
      padding: 1rem;
    }

    td {
      padding: 1rem;
    }

    tr, tr li {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }

    tr ul, tr ol {
      margin: 0;
      padding-left: 2rem;

      li {
      }
    }
  }

  @media (min-width: $breakpoint-extra-small) {

  }

  @media (min-width: $breakpoint-small) {

  }

  @media (min-width: $breakpoint-medium) {
    
  }
}