.team-list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
}

@media (min-width: $breakpoint-extra-small) {
  .team-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
  }
}

@media (min-width: $breakpoint-small) {
  .team-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
  }
}

@media (min-width: $breakpoint-medium) {
  .team-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 20px;
  }
}

@media (min-width: $breakpoint-large) {
  .team-list {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-column-gap: 25px;
    grid-row-gap: 45px;
  }
}

.team-list__item {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

// People
.team-member__image {
  max-width: 100%;
  max-height: 100%;
}

.team-member__info {
  margin-top: 1rem;
}

.team-member__title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.24rem;
  margin: 0;
}

.team-member__subtitle {
  font-size: 1.3rem;
  line-height: 1.95rem;
  margin: 0;
  word-wrap: break-word;
}
