// Lists

ul,
ol,
.list {
}

ul li,
ol li,
.list__item {
  font-size: 2.2rem;
  line-height: 3.33rem;
}

.list-title {
  font-size: 2rem;
  line-height: 2rem;
  color: $color-primary;
  margin: 0 0 3rem 0;
}

.list--reset {
  margin: 0;
  padding: 0;
  
  .list__item {
    list-style: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    margin-bottom: 2.2rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list--small {
  .list__item {
    margin-bottom: 1.2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  
  .list__item .key,
  .list__item .value {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.list--borders {
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  
  &:before,
  &:after {
    content: '';
    height: 1px;
    background-color: $color-primary;
    width: 5rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  &:after {
    top: auto;
    bottom: 0;
  }
}

.list--key-values {
  .list__item {
    display: flex;
    justify-content: space-between;
    
    .key {
      font-weight: 500;
      width: 40%;
    }
    
    .value {
      width: 60%;
    }
  }
}

// Allow for nesting
.list--key-values {
  .values {
    padding: 0;
    width: 60%;
    
    .value {
      margin: 0 0 2.2rem 0;
      width: 100%;
      font-size: 2.2rem;
      line-height: 3.33rem;
    }
    
    .value:last-child {
      margin-bottom: 0;
    }
  }
}

.list--key-values.list--small {
  .values {
    .value {
      margin: 0 0 1.2rem 0;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    .value:last-child {
      margin-bottom: 0;
    }
  }
}

.list--key-values--between {
  .list__item {
    display: flex;
    justify-content: space-between;
    
    .key {
      width: auto;
    }
    
    .value {
      width: auto;
    }
  }
}

.list--key-values--equal {
  .list__item {
    .key {
      width: 50%;
      padding-right: 5%;
    }
    
    .value {
      width: 50%;
    }
  }
}