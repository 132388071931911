.blurb {
  h2 {
    font-size: 2.6rem;
    line-height: 3.64rem;
    margin: 0 0 2rem 0;
  }
}

.blurb__text {
}

.blurb__link {
  margin-top: 3rem;

  a {
    text-decoration: none;
  }
}