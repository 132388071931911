/**
 * In contrast to post content, these styles are for the smaller blurbs and
 * pieces of user generated text around the site.
 **/
.text-content {
  a {
    display: inline;
  }

  img {
    display: block;
    max-width: 100%;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;
  } 

  li {
     font-size: 1.6rem;
    line-height: 2.4rem;
  }

  @media (min-width: $breakpoint-extra-small) {
    img {
      
    }

    ul, ol {
      margin: 3rem 0;
    }
  }

  @media (min-width: $breakpoint-small) {
    ul, ol {
      li {
        margin-bottom: 1.5rem;
      }
    }
  }

  @media (min-width: $breakpoint-medium) {
    img {
      margin: 6rem 0;
    }

    ul, ol {
      margin: 6rem 3rem;
    }
  }
}