.swiper {
  position: relative;
  width: 100%;
}

.swiper-container {
  width: 100%;
}

.swiper-slide {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.swiper-image, .swiper-video {
  max-height: 100%;
  max-width: 100%;
}

.swiper-buttons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
}

.swiper-button-prev,
.swiper-button-next {
  background-size: 12px 18px;
  width: 14px;
  height: 30px;
  margin: 0 .5rem;
  position: static;
  outline: 0;
}

.swiper-wrapper {
}

// Thumbnails 

.swiper-thumbnails {
}

.swiper-thumbnails .swiper-wrapper {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.swiper-thumbnail {
  transition: all 200ms linear;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 1rem 0 0 1rem;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
}

  .swiper-thumbnail--active {
    opacity: 1;
  }

@media (max-width: $breakpoint-small) {
  .swiper-thumbnails .swiper-wrapper {
    justify-content: flex-start;
  }
  
  .swiper-thumbnail {
    width: 45px;
    height: 45px;
    margin: 1rem 1rem 0 0;
  }
}

// Modifiers 

.swiper.reverse {
  .swiper-slide {
    justify-content: flex-start;
  }

  .swiper-thumbnails .swiper-wrapper {
    justify-content: flex-start;
  }

  .swiper-thumbnail {
    margin: 1rem 1rem 0 0;
  }
}