.menu {
  margin-top: 10px;
}

  .menu--icons {
  
  }

.menu__title {

}

.menu__list {
  margin: 0;
  padding: 0;
}

.menu__list__item {
  margin: 0 0 1.5rem 0;
  padding: 0;
  list-style: none;
}

.menu__list__link {
  display: block;
  color: $color-dark-gray;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.menu__list__link--active {
  color: $color-primary;
}