.gallery {
  display: flex;
}

.gallery__row {
  display: flex;
  flex-direction: row;
}

.gallery__col {
  
}