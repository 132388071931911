@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
