$breakpoint-large: 1400px;
$breakpoint-medium: 1200px;
$breakpoint-small: 768px;
$breakpoint-extra-small: 480px;

$grid-container-margin: 25px;
$grid-gutter-width: 50px;
$grid-half-gutter-width: $grid-gutter-width * 0.5;
$grid-gutter-compensation: $grid-gutter-width * 0.5 * -1;

$color-primary: #2DBDD3;

$color-white: #ffffff;
$color-off-black: #222222;
$color-dark-gray: #383D42;
$color-medium-gray: #73778C;
$color-light-gray: #D7D8D9;
$color-off-white: #fefefe;