.contact {
}


.contact__title,
.contact__address,
.contact__links {
  font-size: 1.3rem;
  line-height: 1.69rem;
  margin: 0;
}

.contact__title {
  font-weight: 500;
  color: $color-primary;
}

.contact__address,
.contact__links {
  margin-top: 2rem;

  a {
    color: $color-white;
  }
}