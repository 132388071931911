.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-header__title {
  margin: 0;

  a {
    color: $color-dark-gray;
    text-decoration: none;
  }
}

.section-header__link {
  margin: 0;
}

@media (min-width: $breakpoint-small) {
  .section-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}