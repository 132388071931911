// Spacer - for maintaining overall page height when switching between
// sticky and non-sticky

.nav-spacer {
  height: 14.5rem;
}

// Wrapper

.nav-wrapper {
  transition: all 100ms linear;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: white;
}

  // Show the menu

  .nav-wrapper--show-menu {
    .nav-menu__list {
      opacity: 1;
    }

    .nav-menu__hamburger .svg--hamburger {
      rect:first-child,
      rect:last-child {
        width: 100%;
      }
    }
  }

// Nav

.nav {
  transition: padding 100ms linear;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 0;
  background-color: white;
}

.nav-logo__image {
  height: 2.5rem;
}

// Menu

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-menu__list {
  transition: all 200ms ease-in;
  opacity: 0;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-menu__item {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0 6rem 0 0;
  padding: 0;
  list-style: none;
  
}

.nav-menu__link {
  color: $color-dark-gray;
}

  .nav-menu__link--active {
    color: $color-primary;
    font-weight: 500;
  }

// Hamburger

.nav-menu__hamburger {
  .svg--hamburger {
    fill: $color-primary;
    height: 1.5rem;
    display: block;

    rect:first-child {
      width: 60%;
    }

    rect:last-child {
      width: 75%;
    }
  }
}


@media (max-width: $breakpoint-medium) {
  .nav-menu__item {
    margin: 0 4rem 0 0;
  }
}

@media (max-width: $breakpoint-small) {
  .nav-logo__image {
    height: 2rem;
  }

  .nav .nav-menu__list {
    display: none;
  }

  .nav-menu__item {
    margin: 0 2rem 0 0;
  }
}

// Stick nav

.nav-wrapper--sticky {
  position: fixed;

  .nav {
    padding: 3rem 0;
  }
}


// Nav overlay

.nav-overlay {
  transition: all 200ms linear;
  visibility: hidden;
  opacity: 0;
  z-index: 100;

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .975);
  display: flex;
  justify-content: center;
  align-items: center;

  .nav-menu__list {
    flex-direction: column;
  }

  .nav-menu__item {
    margin: 0 0 3rem 0;
    opacity: 1;
  }

  .nav-menu__link {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.nav-overlay__close {
  position: absolute;
  top: 6rem;
  right: 2.5rem;

  svg {
    height: 2.75rem;
    fill: $color-dark-gray;
  }
}

@media (max-width: $breakpoint-small) {
  .nav-wrapper--show-overlay {
    .nav-overlay, .nav-menu__list {
      visibility: visible;
      opacity: 1;
    }
  }
}