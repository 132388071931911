.post {

}

.post__date {
  margin: 0;
}

.post__title {
  margin: 0;
}

.post__subtitle {
  margin-top: 1rem;
  font-weight: 300;
  color: $color-light-gray;
  font-size: 2rem;
  line-height: 2.4rem;
}


// Project listing

.post-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.post-list__item {
  margin: 0 0 4rem 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

@media (min-width: $breakpoint-extra-small) {
  .post-list__item {
    width: 100%;
  }
}


@media (min-width: $breakpoint-small) {
  .post-list__item {
    width: 47.5%;
    margin: 0 0 4rem 0;
  }
}

@media (min-width: $breakpoint-medium) {
  .post-list__item {
    width: 32%;
    margin: 0 0 4rem 0;
  }
}

// Image

.post__image--fullwidth {
  justify-content: flex-start;
}

.post__image__content {
  display: flex;
  justify-content: flex-end;
  max-height: 40rem;
  height: 40rem;
}

.post__image__img {
  max-height: 100%;
  height: 100%;
  width: auto;
}

@media (max-width: $breakpoint-small) {
  .post__image__content {
    display: block;
    max-height: none;
    height: auto;
    width: 100%;
  }

  .post__image__img {
    max-width: 100%;
    max-height: none;
    height: auto;
    width: 100%;
  }
}

.post-footer {
  display: flex;
  justify-content: space-between;
}