// Project detail

.project {
}

.project__title {
  margin: 0;
  line-height: 4.2rem;
}

.project-map {
  width: 100%;
  margin-bottom: 2rem;
}


// Project listing

.project-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.project-list__item {
  margin: 0 0 4rem 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

@media (min-width: $breakpoint-extra-small) {
  .project-list__item {
    width: 47.5%;
  }
}


@media (min-width: $breakpoint-small) {
  .project-list__item {
    margin: 0 0 4rem 0;
  }
}

@media (min-width: $breakpoint-medium) {
  .project-list__item {
    width: 24%;
    margin: 0 0 4rem 0;
  }
}