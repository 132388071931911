.pagination {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.pagination__item {
    list-style: none;
    padding: 0;
    margin: 0 1.5rem 0 0;
    display: flex;
    align-items: center;
    
    &--disabled {
        .pagination__link {
            color: $color-light-gray;
        }
    }

    &--previous,
    &--next {
        margin-top: 2px;
    }
    
    &--next {
        transform: rotate(180deg);
    }

   
}

.pagination__link {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 300;

    img {
        height: 1.5rem;
    }
}